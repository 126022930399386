import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import fetchUpload from 'lib/fetchUpload';
import functions from 'lib/functions';

const domain = (process.env.NODE_ENV === 'development') ?
  'http://localhost:54321/functions/v1/cmsapi/' :
  'https://rlrsahkegsezipmbvvcs.supabase.co/functions/v1/cmsapi/'

const Announcement = (props) => {
  const toast = useRef(null);
  const uploadRef = useRef(null);
  const [announcements, setAnnouncements] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [announcementDetail, setAnnouncementDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [filters, setFilters] = useState({
    content: { value: null, matchMode: FilterMatchMode.CONTAINS },
    visibled: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'announcement',
      title: '後台公告'
    });
    getAnnouncements();
  }, []);

  const refreshList = () => {
    setAnnouncements(null);
    getAnnouncements();
  };

  const getAnnouncements = async () => {
    const responseList = await fetchApi('announcement/list');
    if (responseList.data?.announcements) {
      setAnnouncements(responseList.data.announcements);
    } else {
      setAnnouncements([]);
    }
  };

  const createAnnouncement = () => {
    setAnnouncementDetail({
      id: null,
      content: '',
      visibled: true,
    });
    setIsEditing(false);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
  };

  const showDetail = async (announcement_id) => {
    setAnnouncementDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
    setTimeout(async () => {
      const response = await fetchApi('announcement/list', {
        id: announcement_id
      });
      if (response.state === 1 && response.data?.announcements?.length > 0) {
        setAnnouncementDetail(response.data.announcements[0]);
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'visibled':
        return row.visibled ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
      case 'content':
        return <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis" style={{ width: 540 }}>{row.content}</div>;
      break;
    };
  };

  const filterElementVisibled = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };

  const onHideSidebar = () => {
    if (isSaving) {
      return false;
    }
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false);
    }
  };

  const updateAnnouncementDetail = (field, value) => {
    setIsUnsaved(true);
    setAnnouncementDetail(announcementDetail => {
      return {
        ...announcementDetail,
        [field]: value,
      };
    });
  };
  
  const save = async () => {
    setIsSaving(true);
    const responseSave = await fetchApi('announcement/save', announcementDetail);
    if (responseSave.state === 1) {
      setAnnouncementDetail({
        ...announcementDetail,
        id: responseSave.data.id
      })
      toast.current.show({ severity: 'success', summary: '後台公告管理', detail: isEditing?'已成功儲存後台公告資料。':'已成功新增後台公告。' });
      setIsUnsaved(false);
      getAnnouncements();
    } else {
      var content = '';
      switch (responseSave.error[0]) {
        default: content = `儲存資料時出現錯誤，請確認資料無誤。`; break;
        case 'content_is_required': content = `必須輸入內容。`; break;
      }
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>{content}</li></ul>,
        type: 'danger',
      });
    }
    setIsEditing(true);
    setIsSaving(false);
  };


  const reorderingAnnouncements = async (e) => {
    const {
      value,
      dragIndex,
      dropIndex
    } = e;
    // update locally
    const announcements = value.map((announcement, index) => {
      return {
        ...announcement,
        ordering: index+1
      };
    });
    setAnnouncements(announcements);
    // update remotely
    const responseReorder = await fetchApi('announcement/reordering', {
      drag: dragIndex + 1,
      drop: dropIndex + 1
    });
  };

  const confirmDeleteAnnouncement = () => {
    confirmDialog({
      message: '你確定要刪除這個後台公告？',
      header: '刪除',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-warning',
      accept: () => {deleteAnnouncement()},
    });

  };

  const deleteAnnouncement = async () => {
    setIsSaving(true);
    const responseDelete = await fetchApi('announcement/delete', {
      id: announcementDetail.id
    });
    if (responseDelete.state === 1) {
      // delete locally
      setAnnouncements(announcements.filter(announcement => announcement.id !== announcementDetail.id));
      // close panel
      setIsOpenDetail(false);
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-start"
          start={<TableTopTitle title="後台公告列表" subTitle="這裡顯示所有於後台儀表板顯示的「公告」。" />}
          end={
            <>
              <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0 mr-3" rounded onClick={refreshList} disabled={announcements===null} />
              <Button label="新增" size="small" icon="pi pi-plus" onClick={createAnnouncement} />
            </>
          }
        />
      </div>
      <DataTable
        value={announcements}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={announcements===null?'讀取中...':'未有記錄'}
        selection={selectedAnnouncement}
        stripedRows
        onSelectionChange={(e) => setSelectedAnnouncement(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
        reorderableRows
        onRowReorder={(e) => reorderingAnnouncements(e)}
      >
        <Column rowReorder headerStyle={{ width: 80 }}></Column>
        {/* <Column field="ordering" header="排序" headerStyle={{ width: 80 }}></Column> */}
        <Column field="content" header="內容" sortable filter filterElement={filterInputText} headerStyle={{ width: 800 }} body={(row) => formatListFields('content', row)}></Column>
        <Column field="created_at" header="建立日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
        <Column field="visibled" header="顯示" body={(row) => formatListFields('visibled', row)} sortable dataType="boolean" filter filterElement={filterElementVisibled}></Column>
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || announcementDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-4 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2" height={160}></Skeleton>
              </div>
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid">
                <div className="col-12 flex align-items-center mb-4">
                  <h2 className="m-0">後台公告內容</h2>
                </div>
                <div className="col-12 flex flex-column gap-2">
                  <label>內容</label>
                  <InputTextarea value={announcementDetail.content} onChange={(e) => updateAnnouncementDetail('content', e.target.value)} autoResize rows={6} style={{ width: '100%' }} />
                </div>
              </div>
              <div className="col-12 flex flex-column gap-2">
                <div className="flex align-items-center justify-content-end">
                  <Checkbox inputId="visibled" name="visibledizza" onChange={(e) => {updateAnnouncementDetail('visibled', e.checked)}} checked={announcementDetail.visibled} />
                  <label htmlFor="visibled" className="ml-2">顯示</label>
                </div>
              </div>
              <Divider />
              <div className="flex justify-content-between gap-2">
                {isEditing ? <Button icon="pi pi-trash" rounded text severity="danger" onClick={confirmDeleteAnnouncement} disabled={isLoadingDetail || isSaving} /> : <div></div>}
                <div className="flex gap-2">
                  <Button label="關閉" size="small" onClick={() => {onHideSidebar()}} outlined disabled={isSaving} />
                  <Button label={isSaving?'儲存中⋯':'儲存'} size="small" onClick={() => {save()}} disabled={isLoadingDetail || isSaving} />
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Announcement;
