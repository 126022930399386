import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import functions from 'lib/functions';

const MARITALS = require('jsons/maritals.json');
const NATIONALITIES = require('jsons/nationalities.json');
const RELIGIONS = require('jsons/religions.json');
const EDUCATIONS = require('jsons/educations.json');
const CONTRACT_STATUSES = require('jsons/contract_statuses.json');
const SKILLS = require('jsons/skills.json');
const COOKING_SKILLS = require('jsons/cooking_skills.json');
const LANGUAGES = require('jsons/languages.json');
const DAYOFFS = require('jsons/dayoffs.json');
const LIVINGS = require('jsons/livings.json');
const JOB_TYPES = require('jsons/job_types.json');
const EMPLOYER_TYPES = require('jsons/employer_types.json');
const WORK_TYPES = require('jsons/work_types.json');
const WORK_START_FLEXIBILITIE = require('jsons/work_start_flexibilities.json');
const PREFERRED_GENDERS = require('jsons/preferred_genders.json');

const Job = (props) => {
  const toast = useRef(null);
  const [jobs, setJobs] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [jobDetail, setJobDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [verifyButtonText, setVerifyButtonText] = useState('更新中⋯')
  const [filters, setFilters] = useState({
    approved: { value: null, matchMode: FilterMatchMode.EQUALS },
    published: { value: null, matchMode: FilterMatchMode.EQUALS },
    employer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    job_title: { value: null, matchMode: FilterMatchMode.CONTAINS },
    job_description: { value: null, matchMode: FilterMatchMode.CONTAINS },
    job_type_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    employer_type_id: { value: null, matchMode: FilterMatchMode.EQUALS },
    work_type_id: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const {
    job_id = ''
  } = useParams();

  useEffect(() => {
    props.setPageParams({
      alias: 'job',
      title: '工作管理'
    });
    getJobs();
    if (job_id !== '') {
      showDetail(job_id);
    }
    if (functions.getUrlAttr('task') === 'approve') {
      setFilters({
        ...filters,
        approved: { value: false, matchMode: FilterMatchMode.EQUALS },
      })
    }
  }, []);

  const refreshList = () => {
    setJobs(null);
    getJobs();
  };

  const getJobs = async () => {
    const responseList = await fetchApi('job/list');
    if (responseList.data?.jobs) {
      setJobs(responseList.data.jobs.map((job) => {
        return {
          ...job,
          employer_name: job.users?.employer_profiles[0]?.employer_name
        };
      }));
    } else {
      setJobs([]);
    }
  };

  const showDetail = async (job_id) => {
    setJobDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsApproving(false);
    setTimeout(async () => {
      const response = await fetchApi('job/list', {
        job_id: job_id
      });
      if (response.state === 1 && response.data?.jobs?.length > 0) {
        setJobDetail(response.data.jobs[0]);
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'approved':
        return row.approved ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'published':
        return row.published ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
      case 'job_description':
        return <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis" style={{ width: 380 }}>{row.job_description}</div>;
      break;
      case 'job_type_id':
        return functions.getObjectValueFromArrayById(JOB_TYPES.zh, row.job_type_id);
      break;
      case 'employer_type_id':
        return functions.getObjectValueFromArrayById(EMPLOYER_TYPES.zh, row.employer_type_id);
      break;
      case 'work_type_id':
        return functions.getObjectValueFromArrayById(WORK_TYPES.zh, row.work_type_id);
      break;
    };
  };

  const filterElementApprove = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterElementPublished = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };
  const filterInputJobType = (options) => {
    const value_object = JOB_TYPES.zh.filter((type) => { return options.value===type.id; });
    const value = (value_object === null) ? null : value_object[0];
    return <Dropdown value={value} options={JOB_TYPES.zh} onChange={(e) => options.filterApplyCallback(e.target.value.id)} optionLabel="name" style={{ width: 100, fontSize: 8 }} className="datatable-filter-dropdown" />;
  };
  const filterInputEmployerType = (options) => {
    const value_object = EMPLOYER_TYPES.zh.filter((type) => { return options.value===type.id; });
    const value = (value_object === null) ? null : value_object[0];
    return <Dropdown value={value} options={EMPLOYER_TYPES.zh} onChange={(e) => options.filterApplyCallback(e.target.value.id)} optionLabel="name" style={{ width: 100 }} className="datatable-filter-dropdown" />;
  };
  const filterInputWorkType = (options) => {
    const value_object = WORK_TYPES.zh.filter((type) => { return options.value===type.id; });
    const value = (value_object === null) ? null : value_object[0];
    return <Dropdown value={value} options={WORK_TYPES.zh} onChange={(e) => options.filterApplyCallback(e.target.value.id)} optionLabel="name" style={{ width: 100 }} className="datatable-filter-dropdown" />;
  };

  const confirmApproveJob = () => {
    let confirmMessage = jobDetail.approved ? '確定要取消枇核此工作？' : '確定要枇核此工作？';
    confirmDialog({
      message: confirmMessage,
      header: '批核工作',
      icon: 'pi pi-info-circle',
      defaultFocus: 'accept',
      acceptClassName: jobDetail.approved ? 'p-button-danger' : 'p-button-success',
      accept: () => {approveJob()},
    });
  };

  const approveJob = async () => {
    setIsApproving(true);
    const to_approved = jobDetail.approved ? 0 : 1;
    const responseVerify = await fetchApi('job/approveJob', {
      job_id: jobDetail.id,
      approved: to_approved
    });
    if (responseVerify.state === 1) {
      toast.current.show({ severity: 'success', summary: '工作審批', detail: to_approved?'已批核此工作。':'已取消批核此工作。' });
      // update this user
      setJobDetail({
        ...jobDetail,
        approved: to_approved
      });
      // update on list
      setJobs(jobs.map((job) => {
        if (job.id === jobDetail.id) {
          return {
            ...job,
            approved: to_approved
          };
        } else {
          return job;
        }
      }));
    } else {
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>抱歉！發生錯誤，請稍後重試。</li></ul>,
        type: 'warning',
      });
    }
    setIsApproving(false);
  };

  const onHideSidebar = () => {
    if (!isSaving && !isApproving) {
      setIsOpenDetail(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-center"
          start={
            <TableTopTitle title="工作列表" subTitle="這裡顯示所有由僱主發佈的「工作」。" />
          }
          end={
            <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0" rounded onClick={refreshList} disabled={jobs===null} />
          }
        />
      </div>
      <DataTable
        value={jobs}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={jobs===null?'讀取中...':'未有記錄'}
        // loading={jobs===null}
        selection={selectedJob}
        stripedRows
        onSelectionChange={(e) => setSelectedJob(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
      >
        <Column field="approved" header="審批" body={(row) => formatListFields('approved', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementApprove}></Column>
        <Column field="published" header="發佈" body={(row) => formatListFields('published', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementPublished}></Column>
        <Column field="employer_name" header="僱主" headerStyle={{ width: 200 }} sortable filter filterElement={filterInputText} showFilterMenu={false}></Column>
        <Column field="job_title" header="工作標題" headerStyle={{ width: 300 }} sortable filter filterElement={filterInputText} showFilterMenu={false}></Column>
        <Column field="job_description" header="工作簡介" body={(row) => formatListFields('job_description', row)} headerStyle={{ width: 400 }} sortable filter filterElement={filterInputText} showFilterMenu={false}></Column>
        <Column field="job_type_id" header="職位種類" body={(row) => formatListFields('job_type_id', row)} headerStyle={{ width: 120 }} sortable filter filterElement={filterInputJobType} showFilterMenu={false}></Column>
        <Column field="employer_type_id" header="僱主類型" body={(row) => formatListFields('employer_type_id', row)} headerStyle={{ width: 120 }} sortable filter filterElement={filterInputEmployerType} showFilterMenu={false}></Column>
        <Column field="work_type_id" header="工作種類" body={(row) => formatListFields('work_type_id', row)} headerStyle={{ width: 120 }} sortable filter filterElement={filterInputWorkType} showFilterMenu={false}></Column>
        <Column field="created_at" header="建立日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || jobDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-2 w-3 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-100 h-4rem"></Skeleton>
              </div>
              <div className="col-12 flex justify-content-end">
                <Skeleton className="mb-2 w-6 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-4">
                <Skeleton className="mb-2 w-10rem"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-4">
                <Skeleton className="mb-2 w-10rem"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-4">
                <Skeleton className="mb-2 w-10rem"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <div className="mb-2 flex">
                  <Skeleton className="mr-2 w-3 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-2 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-3 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-2 h-2rem"></Skeleton>
                </div>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <div className="mb-2 flex">
                  <Skeleton className="mr-2 w-2 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-3 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-4 h-2rem"></Skeleton>
                </div>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <div className="mb-2 flex">
                  <Skeleton className="mr-2 w-2 h-2rem"></Skeleton>
                  <Skeleton className="mr-2 w-4 h-2rem"></Skeleton>
                </div>
              </div>
              <Divider />
              <div className="col-12 flex justify-content-end">
                <Skeleton className="mb-2 w-6 h-2rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid user-detail-user">
                <div className="col-6 flex align-items-center">
                  <h2 className="m-0 mr-2">工作詳情</h2>
                  {jobDetail.approved ?
                    <Tag severity="success" value="已批核" className="text-lg" />
                  :
                    <Tag severity="danger" value="未批核" className="text-lg" />
                  }
                  {jobDetail.published ?
                    <Tag severity="success" value="公開" className="text-lg ml-1" />
                  :
                    <Tag severity="danger" value="不公開" className="text-lg ml-1" />
                  }
                </div>
                <div className="col-6 flex justify-content-end">
                  {jobDetail.approved ?
                    <Button label={isApproving?verifyButtonText:'按此取消批核'} size="small" severity="danger" tooltip="取消批核後，此工作將不會被公開。" tooltipOptions={{ position: 'left' }} onClick={confirmApproveJob} disabled={isSaving||isApproving} />
                  :
                    <Button label={isApproving?verifyButtonText:'按此審批此工作'} size="small" severity="success" onClick={confirmApproveJob} disabled={isSaving||isApproving} />
                  }
                </div>
                <div className="col-12">
                  <Message severity="info" text="工作在審批後及被用戶設置成「公開」後可被其他用戶搜尋及查閱。" />
                </div>
                <div className="col-12 flex justify-content-end">
                  <div className="text-base text-400 mt-3">建立日期：{moment(jobDetail.updated_at).format('YYYY年M月D日 HH:mm')}</div>
                </div>
                <Divider align="left">僱主檔案</Divider>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-user mr-2"></i>僱主名稱</div>
                  <div className="text-xl text-justify">{jobDetail.users?.employer_profiles[0]?.employer_name}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-user mr-2"></i>關於僱主</div>
                  <div className="text-xl text-justify">{jobDetail.users?.employer_profiles[0]?.about_employer}&nbsp;</div>
                </div>
                <Divider align="left">職位內容</Divider>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-briefcase mr-2"></i>工作標題</div>
                  <div className="text-xl text-justify">{jobDetail.job_title}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-briefcase mr-2"></i>工作簡介</div>
                  <div className="text-xl text-justify">{jobDetail.job_description}</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-verified mr-2"></i>僱主類型</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(EMPLOYER_TYPES.zh, jobDetail.employer_type_id)}</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>職位種類</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(JOB_TYPES.zh, jobDetail.job_type_id)}</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>工作種類</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(WORK_TYPES.zh, jobDetail.work_type_id)}</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar mr-2"></i>開始工作日期</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(WORK_START_FLEXIBILITIE.zh, jobDetail.work_start_flexibility_id)}</div>
                </div>
                {jobDetail.work_start_flexibility_id === 1 &&
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar mr-2"></i>固定日期</div>
                  <div className="text-xl text-justify">{moment(jobDetail.work_start).format('YYYY年M月D日')}</div>
                </div>}
                <Divider align="left">技能要求</Divider>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>一般技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(SKILLS.zh, jobDetail.skill).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>烹飪技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(COOKING_SKILLS.zh, jobDetail.cooking_skill).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-language mr-2"></i>語言技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(LANGUAGES.zh, jobDetail.language_proficiency).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-wrench mr-2"></i>其他技能</div>
                  <div className="text-xl text-justify">{jobDetail.skill_remark}&nbsp;</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-graduation-cap mr-2"></i>最低學歷要求</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(EDUCATIONS.zh, jobDetail.required_education_id)}</div>
                </div>
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar mr-2"></i>年資</div>
                  <div className="text-xl text-justify">{jobDetail.preferred_work_experience_from} 至 {jobDetail.preferred_work_experience_to} 年</div>
                </div>
                <Divider align="left">其他要求</Divider>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-venus mr-2"></i>性別</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(PREFERRED_GENDERS.zh, jobDetail.preferred_gender_id)}</div>
                </div>
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar mr-2"></i>年齡</div>
                  <div className="text-xl text-justify">{jobDetail.preferred_age_from} 至 {jobDetail.preferred_age_to} 歲</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-globe mr-2"></i>國籍</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(NATIONALITIES.zh, jobDetail.preferred_nationality).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-sparkles mr-2"></i>宗教</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(RELIGIONS.zh, jobDetail.preferred_religion).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-file-check mr-2"></i>合約狀態</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(CONTRACT_STATUSES.zh, jobDetail.contract_status_id)}</div>
                </div>
                <Divider align="left">假期、住宿及薪酬</Divider>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-sun mr-2"></i>提供假期</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(DAYOFFS.zh, jobDetail.provided_dayoff).map((item, index) => <Tag key={index} value={item} className="text-lg mr-1"></Tag>)}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-home mr-2"></i>提供住宿</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(LIVINGS.zh, jobDetail.provided_living_id)}</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400 mb-1"><i className="pi pi-home mr-2"></i>薪酬面議</div>
                  <div className="text-xl text-justify">{jobDetail.salary_negotiable ? '是' : '否'}</div>
                </div>
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-calendar mr-2"></i>提供薪酬</div>
                  <div className="text-xl text-justify">HK$ {jobDetail.salary_from.toLocaleString()} 至 {jobDetail.salary_to.toLocaleString()}</div>
                </div>
                <Divider />
                <div className="col-12 flex justify-content-end">
                  <div className="text-base text-400 mb-1">最後更新日期：{jobDetail.updated_at===null?'-':moment(jobDetail.updated_at).format('YYYY年M月D日 HH:mm')}</div>
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Job;
