import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import functions from 'lib/functions';

const NotFound = (props) => {
  useEffect(() => {
    functions.setBodyPage('notfound');
  }, []);
  return (
    <>
      <div className="flex flex-column h-full justify-content-center align-items-center">
        <div className="mb-4">
          404 找不到網頁
        </div>
        <a href="/dashboard" className="p-button no-underline">返回儀表板</a>
      </div>
    </>
  );
};
  
export default NotFound;
  