import React, { useEffect, useState, useRef } from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import moment from 'moment-timezone';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const JOB_TYPES = require('jsons/job_types.json');
const MARITALS = require('jsons/maritals.json');
const NATIONALITIES = require('jsons/nationalities.json');
const RELIGIONS = require('jsons/religions.json');
const EDUCATIONS = require('jsons/educations.json');
const WORK_TYPES = require('jsons/work_types.json');
const CONTRACT_STATUSES = require('jsons/contract_statuses.json');
const CHINESE_ZODIACS = require('jsons/chinese_zodiacs.json');
const DAYOFFS = require('jsons/dayoffs.json');
const LIVINGS = require('jsons/livings.json');
const SKILLS = require('jsons/skills.json');
const COOKING_SKILLS = require('jsons/cooking_skills.json');
const LANGUAGES = require('jsons/languages.json');

const HelperUpdate = (props) => {
  const toast = useRef(null);
  const [detail, setDetail] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    props.setPageParams({
      alias: 'helperupdate',
      title: '外傭檔案編輯工具'
    });
    getProfile();
  }, []);

  const getProfile = async () => {
    const response = await fetchApi('user/list', {
      user_type_id: 2,
      user_id: functions.getUrlAttr('helper_profile_id')
    });
    if (response.data.users.length > 0) {
      setDetail(response.data.users[0]);
      setProfile(response.data.users[0].functional_profile);
    }
  };

  const updateProfile = (field, value) => {
    setProfile({
      ...profile,
      [field]: value
    });
  };

  const updateProfileCheckbox = (field, checked, value) => {
    const old_value = profile[field];
    var new_value = [];
    if (checked) {
      new_value = [
        ...old_value,
        value
      ];
    } else {
      new_value = old_value.filter(item => item !== value);
    }
    updateProfile(field, new_value);
  };

  const getRandomData = async (field) => {
    try {
      const response = await fetchApi('https://randomuser.me/api/');
      console.log(response);
    } catch(e) {}
  };

  const confirmSaveProfile = () => {
    confirmDialog({
      message: '你確定要儲存並覆寫原有檔案內容嗎？',
      header: '確定儲存並覆寫',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-info',
      accept: () => saveProfile(),
    });
  };

  const saveProfile = async () => {
    setIsSaving(true);
    const responseUpdate = await fetchApi('user/updateHelperProfile', profile);
    if (responseUpdate.state === 1) {
      toast.current.show({ severity: 'success', summary: '編輯外傭資料', detail: '外傭資料已儲存成功' });
    } else {
      toast.current.show({ severity: 'error', summary: '編輯外傭資料', detail: '儲存失敗，請檢查所有欄位必須輸入' });
    }
    setIsSaving(false);
  };

  return (
    <>
      <div className="text-3xl font-medium">外傭檔案編輯工具</div>
      <div className="flex justify-content-center mt-4 mb-6">
        <Message severity="warn" text="注意：編輯外傭檔案將完全覆寫原有檔案資料並無法還原，請小心使用此工具！" />
      </div>
      {profile === null ?
        <div className="flex justify-content-center m-6">
           <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
         </div>
      :
        <div className="grid">
          <div className="col-12 lg:col-3 flex flex-column gap-2"></div>
          <div className="col-12 lg:col-6 flex flex-column gap-2">
            <div className="grid w-full row-gap-3">
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">User ID</label>
                <InputText value={detail.id} disabled />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">First Name</label>
                <InputText value={detail.first_name} disabled />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Last Name</label>
                <InputText value={detail.last_name} disabled />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Helper Profile ID</label>
                <InputText value={profile.id} disabled />
              </div>
              <Divider />
              {/* <div className="col-12 lg:col-12 flex flex-column gap-2">
                <Button link label="填入隨機資料" onClick={getRandomData} />
              </div> */}
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Job Type</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={JOB_TYPES.en.filter(item => parseInt(item.id)===profile.job_type_id)[0]} onChange={(e) => updateProfile('job_type_id', parseInt(e.value.id))} options={JOB_TYPES.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">First Name</label>
                <InputText value={profile.first_name} onChange={(e) => {updateProfile('first_name', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Last Name</label>
                <InputText value={profile.last_name} onChange={(e) => {updateProfile('last_name', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Year of Birth</label>
                <InputNumber value={profile.year_of_birth} onChange={(e) => {updateProfile('year_of_birth', e.value)}} useGrouping={false} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Mobile</label>
                <InputText value={profile.mobile} onChange={(e) => {updateProfile('mobile', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">WhatsApp</label>
                <InputText value={profile.whatsapp} onChange={(e) => {updateProfile('whatsapp', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Gender</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  <div className="flex align-items-center">
                      <RadioButton inputId="gender-1" name="gender" onChange={(e) => updateProfile('gender', 1)} checked={profile.gender === 1} />
                      <label htmlFor="gender-1" className="ml-2">Female</label>
                  </div>
                  <div className="flex align-items-center">
                      <RadioButton inputId="gender-2" name="gender" onChange={(e) => updateProfile('gender', 2)} checked={profile.gender === 2} />
                      <label htmlFor="gender-2" className="ml-2">Male</label>
                  </div>
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Valid Passport</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  <div className="flex align-items-center">
                      <RadioButton inputId="valid_passport-1" name="valid_passport" onChange={(e) => updateProfile('valid_passport', 1)} checked={profile.valid_passport} />
                      <label htmlFor="valid_passport-1" className="ml-2">Yes</label>
                  </div>
                  <div className="flex align-items-center">
                      <RadioButton inputId="valid_passport-0" name="valid_passport" onChange={(e) => updateProfile('valid_passport', 0)} checked={!profile.valid_passport} />
                      <label htmlFor="valid_passport-0" className="ml-2">No</label>
                  </div>
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Marital</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={MARITALS.en.filter(item => item.id===profile.marital_id)[0]} onChange={(e) => updateProfile('marital_id', e.value.id)} options={MARITALS.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Childs</label>
                <InputNumber value={profile.childs} onChange={(e) => {updateProfile('childs', e.value)}} useGrouping={false} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Nationality</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={NATIONALITIES.en.filter(item => item.id===profile.nationality_id)[0]} onChange={(e) => updateProfile('nationality_id', e.value.id)} options={NATIONALITIES.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Living Place</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={NATIONALITIES.en.filter(item => item.id===profile.place_id)[0]} onChange={(e) => updateProfile('place_id', e.value.id)} options={NATIONALITIES.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Education</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={EDUCATIONS.en.filter(item => parseInt(item.id)===profile.education_id)[0]} onChange={(e) => updateProfile('education_id', parseInt(e.value.id))} options={EDUCATIONS.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Religion</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={RELIGIONS.en.filter(item => parseInt(item.id)===profile.religion_id)[0]} onChange={(e) => updateProfile('religion_id', parseInt(e.value.id))} options={RELIGIONS.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Work Type</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={WORK_TYPES.en.filter(item => parseInt(item.id)===profile.work_type_id)[0]} onChange={(e) => updateProfile('work_type_id', parseInt(e.value.id))} options={WORK_TYPES.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Work Experience</label>
                <InputNumber value={profile.work_experience} onChange={(e) => {updateProfile('work_experience', e.value)}} useGrouping={false} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Contract Status</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={CONTRACT_STATUSES.en.filter(item => parseInt(item.id)===profile.contract_status_id)[0]} onChange={(e) => updateProfile('contract_status_id', parseInt(e.value.id))} options={CONTRACT_STATUSES.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Available Date</label>
                <Calendar value={new Date(profile.available_date)} onChange={(e) => {updateProfile('available_date', moment(e.target.value).format('YYYY-MM-DD'))}} dateFormat="yy-mm-dd" />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Expected Salary</label>
                <InputNumber value={profile.expected_salary} onChange={(e) => {updateProfile('expected_salary', e.value)}} useGrouping={false} />
              </div>
              <div className="col-12 lg:col-3 flex flex-column gap-2">
                <label className="font-semibold">Chinese Zodiac</label>
                <div className="flex flex-wrap gap-3">
                  <Dropdown value={CHINESE_ZODIACS.en.filter(item => parseInt(item.id)===profile.chinese_zodiac_id)[0]} onChange={(e) => updateProfile('chinese_zodiac_id', parseInt(e.value.id))} options={CHINESE_ZODIACS.en} optionLabel="name" placeholder="Select" className="w-full" />
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <label className="font-semibold">Preferred Dayoff</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  {DAYOFFS.en.map((item, index) => {
                    return (
                      <div className="flex align-items-center" key={index}>
                        <Checkbox inputId={`dayoff_id-${item.id}`} name="dayoff_id" onChange={(e) => updateProfileCheckbox('preferred_dayoff', e.checked, parseInt(item.id))} checked={profile.preferred_dayoff.includes(parseInt(item.id))} />
                        <label htmlFor={`dayoff_id-${item.id}`} className="ml-2">{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <label className="font-semibold">Preferred Living</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  {LIVINGS.en.map((item, index) => {
                    return (
                      <div className="flex align-items-center" key={index}>
                        <Checkbox inputId={`living_id-${item.id}`} name="living_id" onChange={(e) => updateProfileCheckbox('preferred_living', e.checked, parseInt(item.id))} checked={profile.preferred_living.includes(parseInt(item.id))} />
                        <label htmlFor={`living_id-${item.id}`} className="ml-2">{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <label className="font-semibold">Language Proficiency</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  {LANGUAGES.en.map((item, index) => {
                    return (
                      <div className="flex align-items-center" key={index}>
                        <Checkbox inputId={`language_id-${item.id}`} name="language_id" onChange={(e) => updateProfileCheckbox('language_proficiency', e.checked, parseInt(item.id))} checked={profile.language_proficiency.includes(parseInt(item.id))} />
                        <label htmlFor={`language_id-${item.id}`} className="ml-2">{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <label className="font-semibold">Skill</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  {SKILLS.en.map((item, index) => {
                    return (
                      <div className="flex align-items-center" key={index}>
                        <Checkbox inputId={`skill_id-${item.id}`} name="skill_id" onChange={(e) => updateProfileCheckbox('skill', e.checked, parseInt(item.id))} checked={profile.skill.includes(parseInt(item.id))} />
                        <label htmlFor={`skill_id-${item.id}`} className="ml-2">{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <label className="font-semibold">Cooking Skill</label>
                <div className="flex flex-wrap gap-3 mt-2">
                  {COOKING_SKILLS.en.map((item, index) => {
                    return (
                      <div className="flex align-items-center" key={index}>
                        <Checkbox inputId={`cooking_skill_id-${item.id}`} name="cooking_skill_id" onChange={(e) => updateProfileCheckbox('cooking_skill', e.checked, parseInt(item.id))} checked={profile.cooking_skill.includes(parseInt(item.id))} />
                        <label htmlFor={`cooking_skill_id-${item.id}`} className="ml-2">{item.name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <div className="flex justify-content-between">
                  <label className="font-semibold">Skill Remark</label>
                </div>
                <InputTextarea value={profile.skill_remark} onChange={(e) => {updateProfile('skill_remark', e.target.value)}} rows={3} autoResize />
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <div className="flex justify-content-between">
                  <label className="font-semibold">Work Experience Remark</label>
                </div>
                <InputTextarea value={profile.work_experience_remark} onChange={(e) => {updateProfile('work_experience_remark', e.target.value)}} rows={3} autoResize />
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <div className="flex justify-content-between">
                  <label className="font-semibold">Education Remark</label>
                </div>
                <InputTextarea value={profile.education_remark} onChange={(e) => {updateProfile('education_remark', e.target.value)}} rows={3} autoResize />
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2">
                <div className="flex justify-content-between">
                  <label className="font-semibold">About Me</label>
                </div>
                <InputTextarea value={profile.about_me} onChange={(e) => {updateProfile('about_me', e.target.value)}} rows={3} autoResize />
              </div>
              <div className="col-12 lg:col-12 flex flex-column gap-2 mt-4">
                <Button label={isSaving?'儲存中⋯':'更新外傭檔案'} disabled={isSaving} onClick={confirmSaveProfile} />
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-3 flex flex-column gap-2"></div>
          <div className="col-12 lg:col-12" style={{ paddingBottom: 200 }}></div>
          <ConfirmDialog />
          <Toast ref={toast} position="bottom-center" />
        </div>
      }
    </>
  );
};
  
export default HelperUpdate;
