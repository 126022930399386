export const app = {
  default_title: 'Helper Station 管理平台',
};

export const menu = [
  { label: '儀表板', icon: 'pi pi-home', alias: 'dashboard', url: '/dashboard', },
  { separator: true },
  { label: '僱主', icon: 'pi pi-face-smile', alias: 'employer', url: '/employer', permission: 'employer' },
  { label: '外傭', icon: 'pi pi-wrench', alias: 'helper', url: '/helper', permission: 'helper' },
  { label: '工作', icon: 'pi pi-briefcase', alias: 'job', url: '/job', permission: 'job' },
  { label: '招聘申請', icon: 'pi pi-thumbs-up', alias: 'hire', url: '/hire', permission: 'hire' },
  { label: '工作申請', icon: 'pi pi-file-check', alias: 'application', url: '/application', permission: 'application' },
  { separator: true },
  { label: '首頁橫幅', icon: 'pi pi-image', alias: 'homebanner', url: '/homebanner', permission: 'homebanner' },
  { label: '常見問題', icon: 'pi pi-question-circle', alias: 'faq', url: '/faq', permission: 'faq' },
  { separator: true },
  { label: '後台公告', icon: 'pi pi-megaphone', alias: 'announcement', url: '/announcement', permission: 'announcement' },
  { label: '管理員', icon: 'pi pi-users', alias: 'administrator', url: '/administrator', permission: 'administrator' },
  { label: '測試', icon: 'pi pi-cog', alias: 'test', url: '/test', permission: 'test', dev: true },
];

export const APPLICATION_STATUSES = [
  {
    id: 'PENDING',
    name: 'PENDING',
    severity: 'info',
  },
  {
    id: 'PROCESSING',
    name: 'PROCESSING',
    severity: 'warning',
  },
  {
    id: 'CONFIRMED',
    name: 'CONFIRMED',
    severity: 'success',
  },
  {
    id: 'REJECTED',
    name: 'REJECTED',
    severity: 'danger',
  },
];