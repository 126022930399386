import React, { useEffect, useState } from 'react';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import functions from 'lib/functions';

const Test = (props) => {
  const [value, setValue] = useState(true);

  useEffect(() => {
    props.setPageParams({
      alias: 'test',
      title: '測試頁'
    });
  }, []);

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <>
      <TriStateCheckbox value={value} onChange={(e) => setValue(e.value)} />
    </>
  );
};
  
export default Test;
