import React, { useEffect, useState, useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import functions from 'lib/functions';

// const USER_GROUPS = [
//   { id: 1, name: '超級管理員' },
//   { id: 2, name: '管理員' },
//   { id: 3, name: '一般用戶' },
// ];
const DEFAUALT_ADMINISTRATOR = {
  id: '',
  fullname: '',
  nickname: '',
  password: '',
  mobile: '',
  email: '',
  // user_group_id: 3,
  // user_group: USER_GROUPS[2],
};

const Administrator = (props) => {
  const toast = useRef(null);
  const [administrators, setAdministrators] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [administratorDetail, setAdministratorDetail] = useState(DEFAUALT_ADMINISTRATOR);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'administrator',
      title: '管理員管理'
    });
    getAdministrators();
  }, []);

  const refreshList = () => {
    setAdministrators(null);
    getAdministrators();
  };

  const getAdministrators = async () => {
    const responseList = await fetchApi('administrator/list');
    if (responseList?.data?.administrators) {
      setAdministrators(responseList.data.administrators.map((administrator) => {
        return {
          ...administrator,
          // user_group_name: user.user_group.group_name,
        };
      }));
    }
  };

  const startCreate = () => {
    setAdministratorDetail(DEFAUALT_ADMINISTRATOR);
    setIsEditing(false);
    setIsOpenDetail(true);
    setIsUnsaved(false);
    setIsSaving(false);
  };

  const startEdit = async (administrator_id) => {
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsUnsaved(false);
    setIsSaving(false);
    const response = await fetchApi('administrator/list', {
      administrator_id: administrator_id,
    });
    if (response?.data?.administrators) {
      let administrator = response.data.administrators[0];
      setAdministratorDetail({
        ...administrator,
        password: '',
        // user_group: USER_GROUPS.filter((group) => { return group.id === user.user_group_id; })[0]
      });
    }
    setIsLoadingDetail(false);
  };

  const updateUserDetail = (field, value) => {
    setIsUnsaved(true);
    if (field === 'user_group') {
      // setAdministratorDetail(administratorDetail => {
      //   return {
      //     ...administratorDetail,
      //     user_group_id: value.id,
      //     user_group: value,
      //   };
      // });
    } else {
      setAdministratorDetail(administratorDetail => {
        return {
          ...administratorDetail,
          [field]: value,
        };
      });
    }
  };

  const save = async () => {
    // validation
    var errors = [];
    if (administratorDetail.fullname.trim() === '') {
      errors.push('必須輸入姓名');
    }
    if (!isEditing) {
      if (!functions.validateValue('password', administratorDetail.password)) {
        errors.push('密碼最少需要8位字元');
      }
    }
    if (!functions.validateValue('email', administratorDetail.email)) {
      errors.push('電郵格式不正確');
    }
    if (errors.length > 0) {
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul>{errors.map((error, i) => { return <li key={i}>{error}</li>; })}</ul>,
        type: 'warning',    
      });
      return false;
    }
    // save
    setIsSaving(true);
    const response = await fetchApi('administrator/save', administratorDetail);
    if (response.state === 1) {
      toast.current.show({ severity: 'success', summary: '管理員管理', detail: isEditing?'已成功儲存管理員資料。':'已成功新增管理員。' });
      setIsUnsaved(false);
      getAdministrators();
    } else {
      var content = '';
      switch (response.error[0]) {
        default: content = `新增管理員時出現錯誤，請確認資料無誤。`; break;
        case 'user_already_exists': content = `電郵地址「${administratorDetail.email}」已被使用。`; break;
        case 'access_denied': content = `權限不足，無法修改超級管理員。`; break;
      }
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>{content}</li></ul>,
        type: 'danger',
      });
    }
    setIsSaving(false);
  };

  const closeDetail = () => {
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false)
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-start"
          start={
            <TableTopTitle title="管理員列表" subTitle="管理員是使用這個管理平台的用戶。" />
          }
          end={
            <>
              <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0 mr-3" rounded onClick={refreshList} disabled={administrators===null} />
              <Button label="新增" onClick={() => {startCreate()}} icon="pi pi-plus" size="small" />
            </>
          }
        />
      </div>
      <DataTable
        value={administrators}
        selectionMode="single"
        onRowClick={(e) => {startEdit(e.data.id)}}
        emptyMessage={administrators===null?'讀取中...':'未有記錄'}
        // loading={administrators===null}
        selection={selectedUser}
        stripedRows
        onSelectionChange={(e) => setSelectedUser(e.value)} dataKey="id"
      >
        <Column field="fullname" header="姓名"></Column>
        <Column field="nickname" header="暱稱"></Column>
        <Column field="email" header="電郵地址"></Column>
        <Column field="mobile" header="電話"></Column>
        {/* <Column field="user_group_name" header="用戶組"></Column> */}
      </DataTable>
      <Dialog
        className="detail-dialog mx-2"
        visible={isOpenDetail}
        closable={false}
        onHide={() => {setIsOpenDetail(false)}}
        header={isEditing ? administratorDetail.fullname : '新增管理員'}
        footer={
          <>
            <Button label="關閉" onClick={() => {closeDetail()}} outlined disabled={isSaving} />
            <Button label="儲存" onClick={() => {save()}} disabled={isLoadingDetail || isSaving} />
          </>
        }
      >
        {isLoadingDetail ?
          <div className="flex justify-content-center m-6">
            <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
          </div>
        :
          <>
            <div className="grid">
              <div className="col-12 lg:col-5 flex flex-column gap-2">
                <label>姓名</label>
                <InputText value={administratorDetail.fullname} onChange={(e) => {updateUserDetail('fullname', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-5 flex flex-column gap-2">
                <label>暱稱</label>
                <InputText value={administratorDetail.nickname} onChange={(e) => {updateUserDetail('nickname', e.target.value)}} />
              </div>
              <div className="col-12 lg:col-2 flex flex-column gap-2">
                <label>電話</label>
                <InputText value={administratorDetail.mobile} onChange={(e) => {updateUserDetail('mobile', e.target.value)}} />
              </div>
              <Divider />
              <div className="col-12 lg:col-7 flex flex-column gap-2">
                <label>電郵地址</label>
                <InputText value={administratorDetail.email} disabled={isEditing} onChange={(e) => {updateUserDetail('email', e.target.value)}} placeholder="demo@example.com" />
                <small>{isEditing?'電郵地址無法修改。':'管理員將使用此電郵地址登入系統。'}</small>
              </div>
              <div className="col-12 lg:col-5 flex flex-column gap-2">
                <label>密碼</label>
                <InputText value={administratorDetail.password} onChange={(e) => {updateUserDetail('password', e.target.value)}} />
                <small>{isEditing?'如不修改密碼，請留空。（最少8個字元）':'用戶將使用此密碼登入系統。（最少8個字元）'}</small>
                <small></small>
              </div>
            </div>
            {/* <div className="grid">
              <div className="col-12 lg:col-6 flex flex-column gap-2">
                <label>用戶組</label>
                <Dropdown value={administratorDetail.user_group} onChange={(e) => updateUserDetail('user_group', e.value)} options={USER_GROUPS} optionLabel="name" placeholder="選取一項" className="w-full" />
              </div>
            </div> */}
          </>
        }
      </Dialog>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Administrator;
