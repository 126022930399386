import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';
import { menu } from 'lib/configs';

const SidebarContainer = (props) => {
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);

  // hardcode menu items here
  let items = [
    ...menu,
    { separator: true },
    { label: '登出', icon: 'pi pi-sign-out', command: () => {askLogout()} }
  ];
  // remove dev menu items
  if (process.env.NODE_ENV !== 'development') {
    items = items.filter((item) => {
      return item.dev !== true;
    });
  }

  useEffect(() => {
    items = items.map((item) => {
      return {
        ...item,
        className: props.currentPageAlias === item.alias ? 'p-focus' : '',
      };
    });
    setMenuItems(items);
  }, []);

  const logout = () => {
    functions.setCookie('hscms-administrator-id', '');
    functions.setCookie('hscms-access-token', '');
    functions.setCookie('hscms-refresh-token', '');
    localStorage.clear();
    navigate('/');
  };

  const askLogout = () => {
    confirmDialog({
      message: '你確定要登出系統嗎？',
      header: '確定登出',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-info',
      accept: () => logout(),
    });
  };

  return (
    <>
      <div className="flex flex-column h-full justify-content-between">
        <div>
          <Menu model={menuItems} className="w-full border-none" />
          <div className="flex justify-content-center mt-4">
            <Tag>v{functions.getVersion()}</Tag>
          </div>
        </div>
        <div className="p-3 pb-0">
          <Chip label={localStorage.getItem('administratorNickname')} icon="pi pi-user" className="w-full border-round-sm" />
        </div>
      </div>
      <ConfirmDialog />
    </>
  );
};

export default SidebarContainer;
