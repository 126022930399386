const Cookies = require('universal-cookie');
var package_json = require('../../package.json');

const getVersion = () => {
  return package_json.version;
};

const getUrlAttr = (field) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(field);
};

const setBodyPage = (pageName) => {
  document.querySelector('body').setAttribute('data-page', pageName);
};

const setCookie = (name, value, options={path:'/'}) => {
  const cookies = new Cookies();
  cookies.set(name, value, options);
};

const getCookie = (name) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

const validateValue = (type, value) => {
  var valid = false;
  var regex;
  switch (type) {
    case 'password':
      regex = /^.{8,}$/;
      valid = regex.test(value);
      break;
    case 'email':
      valid = String(value)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      break;
  };
  return valid;
};

const dateToString = (date) => {
  try {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  } catch (e) {
    return '';
  }
};

const dateToChinese = (date) => {
  try {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
  } catch (e) {
    return '';
  }
};

const dateToTimeString = (date) => {
  try {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } catch (e) {
    return '';
  }
}

const dateToTimeChinese = (date) => {
  try {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const period = hours < 12 ? '上午' : '下午';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${period} ${formattedHours}:${minutes}`;
  } catch (e) {
    return '';
  }
};

const showEmptyValueOrHyphen = (value, nullText='-') => {
  return value === '' || value === null ? nullText : value;
};

const showEmptyZeroValueOrHyphen = (value, nullText='-') => {
  return value === '' || value === null || value === 0 ? nullText : value;
};

const getObjectValueFromArrayById = (array, id, value_key='name') => {
  id = id === null ? '' : id;
  var value = '';
  array.forEach((item) => {
    if (item.id.toString() === id.toString()) {
      value = item[value_key];
    }
  });
  return value;
};

const getObjectValuesFromArrayByIds = (array, ids, value_key='name') => {
  return array.filter((item) => {
    return ids.map(id => id.toString()).includes(item.id.toString());
  }).map((item) => {
    return item[value_key];
  });
};

module.exports = {
  getVersion,
  getUrlAttr,
  setBodyPage,
  setCookie,
  getCookie,
  validateValue,
  dateToString,
  dateToChinese,
  dateToTimeString,
  dateToTimeChinese,
  showEmptyValueOrHyphen,
  showEmptyZeroValueOrHyphen,
  getObjectValueFromArrayById,
  getObjectValuesFromArrayByIds,
};

