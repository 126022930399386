import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import fetchUpload from 'lib/fetchUpload';
import functions from 'lib/functions';

const domain = (process.env.NODE_ENV === 'development') ?
  'http://localhost:54321/functions/v1/cmsapi/' :
  'https://rlrsahkegsezipmbvvcs.supabase.co/functions/v1/cmsapi/'

const HomeBanner = (props) => {
  const toast = useRef(null);
  const uploadRef = useRef(null);
  const [homebanners, setHomebanners] = useState(null);
  const [selectedHomebanner, setSelectedHomebanner] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [homebannerDetail, setHomebannerDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [filters, setFilters] = useState({
    title: { value: null, matchMode: FilterMatchMode.CONTAINS },
    url: { value: null, matchMode: FilterMatchMode.CONTAINS },
    visibled: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'homebanner',
      title: '首頁橫幅'
    });
    getHomebanners();
  }, []);

  const refreshList = () => {
    setHomebanners(null);
    getHomebanners();
  };

  const getHomebanners = async () => {
    const responseList = await fetchApi('homebanner/list');
    if (responseList.data?.homebanners) {
      setHomebanners(responseList.data.homebanners);
    } else {
      setHomebanners([]);
    }
  };

  const createHomebanner = () => {
    setHomebannerDetail({
      id: null,
      title: '',
      url: '',
      bannerimage: null,
      bannerimage_public_url: null,
      visibled: true,
    });
    setIsEditing(false);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
  };

  const showDetail = async (homebanner_id) => {
    setHomebannerDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
    setTimeout(async () => {
      const response = await fetchApi('homebanner/list', {
        id: homebanner_id
      });
      if (response.state === 1 && response.data?.homebanners?.length > 0) {
        setHomebannerDetail(response.data.homebanners.map((banner) => {
          return {
            ...banner,
            bannerimage: 'appmedia/' + banner.bannerimage.split('/appmedia/')[1]
          };
        })[0]);
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'visibled':
        return row.visibled ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
    };
  };

  const filterElementVisibled = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };

  const onHideSidebar = () => {
    if (isSaving) {
      return false;
    }
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false);
    }
  };

  const updateHomebannerDetail = (field, value) => {
    setIsUnsaved(true);
    setHomebannerDetail(homebannerDetail => {
      return {
        ...homebannerDetail,
        [field]: value,
      };
    });
  };
  
  const uploadBannerImage = async (e) => {
    uploadRef.current.uploading = false;
    setIsUploading(true);
    const responseUpload = await fetchUpload('file/upload', e.files, 'homebanner/');
    if (responseUpload.state === 1) {
      setHomebannerDetail({
        ...homebannerDetail,
        bannerimage: 'appmedia/' + responseUpload.path.split('/appmedia/')[1],
        bannerimage_public_url: responseUpload.path
      });
    }
    setIsUploading(false);
  };

  const removeBannerImage = () => {
    setHomebannerDetail({
      ...homebannerDetail,
      bannerimage: null,
      bannerimage_public_url: null
    });
    setIsUnsaved(true);
  };

  const save = async () => {
    setIsSaving(true);
    const responseSave = await fetchApi('homebanner/save', homebannerDetail);
    if (responseSave.state === 1) {
      setHomebannerDetail({
        ...homebannerDetail,
        id: responseSave.data.id
      })
      toast.current.show({ severity: 'success', summary: '首頁橫幅管理', detail: isEditing?'已成功儲存橫幅資料。':'已成功新增首頁橫幅。' });
      setIsUnsaved(false);
      getHomebanners();
    } else {
      var content = '';
      switch (responseSave.error[0]) {
        default: content = `儲存資料時出現錯誤，請確認資料無誤。`; break;
        case 'title_is_required': content = `必須輸入橫幅名稱。`; break;
        case 'banner_is_required': content = `必須上傳橫幅圖片。`; break;
      }
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>{content}</li></ul>,
        type: 'danger',
      });
    }
    setIsSaving(false);
  };


  const reorderingHomebanners = async (e) => {
    const {
      value,
      dragIndex,
      dropIndex
    } = e;
    // update locally
    const homebanners = value.map((banner, index) => {
      return {
        ...banner,
        ordering: index+1
      };
    });
    setHomebanners(homebanners);
    // update remotely
    const responseReorder = await fetchApi('homebanner/reordering', {
      drag: dragIndex + 1,
      drop: dropIndex + 1
    });
  };

  const confirmDeleteHomebanner = () => {
    confirmDialog({
      message: '你確定要刪除這個首頁橫幅？',
      header: '刪除',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-warning',
      accept: () => {deleteHomebanner()},
    });

  };

  const deleteHomebanner = async () => {
    setIsSaving(true);
    const responseDelete = await fetchApi('homebanner/delete', {
      id: homebannerDetail.id
    });
    if (responseDelete.state === 1) {
      // delete locally
      setHomebanners(homebanners.filter(homebanner => homebanner.id !== homebannerDetail.id));
      // close panel
      setIsOpenDetail(false);
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-center"
          start={<TableTopTitle title="橫幅列表" subTitle="這裡顯示所有「首頁橫幅」。" />}
          end={
            <>
              <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0 mr-3" rounded onClick={refreshList} disabled={homebanners===null} />
              <Button label="新增" size="small" icon="pi pi-plus" onClick={createHomebanner} />
            </>
          }
        />
      </div>
      <DataTable
        value={homebanners}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={homebanners===null?'讀取中...':'未有記錄'}
        selection={selectedHomebanner}
        stripedRows
        onSelectionChange={(e) => setSelectedHomebanner(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
        reorderableRows
        onRowReorder={(e) => reorderingHomebanners(e)}
      >
        <Column rowReorder headerStyle={{ width: 80 }}></Column>
        {/* <Column field="ordering" header="排序" headerStyle={{ width: 80 }}></Column> */}
        <Column field="title" header="橫幅名稱" sortable filter filterElement={filterInputText}></Column>
        <Column field="url" header="外部連結" sortable filter filterElement={filterInputText}></Column>
        <Column field="created_at" header="建立日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
        <Column field="visibled" header="顯示" body={(row) => formatListFields('visibled', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementVisibled}></Column>
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || homebannerDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-4 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2" height={240}></Skeleton>
              </div>
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid">
                <div className="col-12 flex align-items-center mb-4">
                  <h2 className="m-0">橫幅內容</h2>
                </div>
                <div className="col-12 flex flex-column gap-2">
                  <label>橫幅名稱</label>
                  <InputText value={homebannerDetail.title} onChange={(e) => {updateHomebannerDetail('title', e.target.value)}} />
                </div>
                <div className="col-12 flex flex-column gap-2">
                  <label>外部連結</label>
                  <InputText value={homebannerDetail.url} onChange={(e) => {updateHomebannerDetail('url', e.target.value)}} placeholder="https://www.external-website.com/" />
                </div>
                <div className="col-12 flex flex-column gap-2">
                  {homebannerDetail.bannerimage_public_url === null ?
                    <>
                      <label>橫幅圖片</label>
                      <FileUpload ref={uploadRef} chooseLabel="選取圖片" customUpload={true} auto uploadHandler={uploadBannerImage} accept="image/*" maxFileSize={10_000_000} emptyTemplate={<p className="text-center"><i className="pi pi-info-circle"></i> 按「+ 選取圖片」或將檔案拖曳到此處上傳。</p>} itemTemplate={isUploading?<ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />:<p className="w-full text-center"><i className="pi pi-check-circle"></i> 上傳完成</p>} />
                    </>
                  :
                    <>
                      <div className="flex justify-content-between">
                        <label>橫幅圖片</label>
                        <Button link label="" icon="pi pi-trash" className="p-0 m-0" onClick={removeBannerImage} />
                      </div>
                      <Image src={homebannerDetail.bannerimage_public_url} alt="Home Banner" preview imageClassName="w-full border-round" />
                    </>
                  }
                </div>
              </div>
              <div className="col-12 flex flex-column gap-2">
                <div className="flex align-items-center justify-content-end">
                  <Checkbox inputId="visibled" name="visibledizza" onChange={(e) => {updateHomebannerDetail('visibled', e.checked)}} checked={homebannerDetail.visibled} />
                  <label htmlFor="visibled" className="ml-2">在App中顯示</label>
                </div>
              </div>
              <Divider />
              <div className="flex justify-content-between gap-2">
                {isEditing ? <Button icon="pi pi-trash" rounded text severity="danger" onClick={confirmDeleteHomebanner} disabled={isLoadingDetail || isSaving} /> : <div></div>}
                <div className="flex gap-2">
                  <Button label="關閉" size="small" onClick={() => {onHideSidebar()}} outlined disabled={isSaving} />
                  <Button label={isSaving?'儲存中⋯':'儲存'} size="small" onClick={() => {save()}} disabled={isLoadingDetail || isSaving} />
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default HomeBanner;
