import { useNavigate } from 'react-router-dom';

import functions from 'lib/functions';

const domain = (process.env.NODE_ENV === 'development') ?
  'http://localhost:54321/functions/v1/cmsapi/' :
  'https://rlrsahkegsezipmbvvcs.supabase.co/functions/v1/cmsapi/'

const fetchUpload = async (url, files=[], folder='', method='POST', headers={}) => {
  const hscmsUserId = functions.getCookie('hscms-user-id') || '';
  const hscmsAccessToken = functions.getCookie('hscms-access-token') || '';
  // console.log('🟢 =======================');
  // console.log('🟢 HSCMS-User-Id:', hscmsUserId);
  // console.log('🟢 HSCMS-Access-Token:', hscmsAccessToken);
  // console.log('🟢 =======================');

  if (files.length === 0) {
    return {
      state: 1,
      error: ['no_files']
    };
  }

  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[i]);
  }
  formData.append('folder', folder);

  return await fetch(url.substring(0,8)==='https://'||url.substring(0,7)==='http://' ? url : domain+url, {
    method: method,
    body: formData,
    headers: new Headers({
      ...headers,
      'HS-API-KEY': process.env.REACT_APP_APIKEY,
      'Authorization': `Bearer ${hscmsAccessToken}`,
    }),
  }).then((response) => {
    return response.json();
  }).then((responseData) => {
    if (responseData.error) {
      if (responseData.error[0] === 'bad_jwt' || responseData.error?.message === 'JWT expired') {
        document.location.href = '/';
      }
    }
    return responseData;
  }).catch((error) => {
    return {
      data: error,
      http_status: 403
    }
  });
};

export default fetchUpload;
